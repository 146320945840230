import angular from 'angular';
import './sortOptions.less';
import template from './sortOptions.html';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('mySortOptions', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            options: '=',
            onSelected: '&'
        },
        controller($scope) {

            $scope.selected = $scope.options?.find(item => item.selected);

            $scope.onSortClicked = ($event) => {
                if ($event) {
                    $event.stopImmediatePropagation();
                }

                $scope.showOptions =! $scope.showOptions;
            };

            $scope.onSortOptionSelectedInt = ($event, option) => {
                $event.stopImmediatePropagation();
                $scope.onSelected({ option });
                $scope.onSortClicked();
                $scope.selected = option;
            };
        }
    };
});
