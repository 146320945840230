import angular from 'angular';
import './homePage.less';
import template from './homePage.html';

if (MyGlobal.project.isMobile) {
    import('./homePage_mobile.less');
}

import './homePage.service';

import './shared/columnHeader/columnHeader';

import './users/users';
import './rooms/rooms';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myHomePage', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            title: '='
        },
        controller($scope, $timeout, helper, $rootScope, homePageService) {

            let feedDropStack = [];
            $scope.homePageService = homePageService;

            $scope.resetToFeed = () => {
                helper.gotoState('default');

                delete $scope.selectedView;
                feedDropStack = [];

                if ($rootScope.isMobile) {
                    delete $scope.selectedSong;
                    homePageService.setMobileSubView(homePageService.SUB_VIEWS.FEED);
                }
            };

            $scope.backToPrevFeedDrop = () => {
                let prevView = feedDropStack.pop();

                // In case of FeedDrop that firstly loaded (on page load) that has a parent and the back button need to gop there
                if (!prevView && $scope.selectedView?.data?.feedDrop?.parentFeedDrop?._id) {
                    prevView = {
                        type: homePageService.SUB_VIEWS.FEED_DROP,
                        data: {
                            id: $scope.selectedView.data.feedDrop.parentFeedDrop._id
                        }
                    };
                }

                switch (prevView?.type) {
                    case homePageService.SUB_VIEWS.FEED_DROP:
                        helper.gotoState('feedDrop', {
                            ...prevView.data,
                            ignoreStack: true
                        });
                        break;
                    case homePageService.SUB_VIEWS.USER_PROFILE:
                        helper.gotoState('user-modal', {
                            ...prevView.data,
                            ignoreStack: true
                        });
                        break;
                    default:
                        $scope.resetToFeed();
                }
            };

            $scope.onFeedDropLoaded = (feedDrop) => {
                if ($scope.selectedView?.data?.id === feedDrop._id) {
                    $scope.selectedView.data.feedDrop = feedDrop;
                }
            };

            const setSelectedView = ({ type, data, ignoreStack, clearStack }) => {

                if (!clearStack && !ignoreStack && $scope.selectedView) {
                    feedDropStack.push($scope.selectedView);
                } else if (clearStack) {
                    feedDropStack = [];
                }

                $timeout(()=>{

                    $scope.selectedView = { type, data };

                    $(window).scrollTop(0);
                    if ($rootScope.isMobile) {
                        homePageService.setMobileSubView(type);
                    }
                });
            };

            $scope.$on('currUserProfile:changed', ($evt, { userName, ignoreStack }) => {
                setSelectedView({
                    type: homePageService.SUB_VIEWS.USER_PROFILE,
                    data: { userName },
                    ignoreStack
                });
            });

            $scope.$on('feedDrop:changed', ($evt, { id, feedDrop, ignoreStack }) => {
                setSelectedView({ type: homePageService.SUB_VIEWS.FEED_DROP, data: { id, feedDrop }, ignoreStack });
            });

            $scope.$on('feed:show', ($evt, { filters, ignoreStack }) => {
                setSelectedView({
                    type: homePageService.SUB_VIEWS.FEED,
                    data: { filters },
                    ignoreStack,
                    clearStack: true
                });

                $rootScope.$broadcast('feed:filtersUpdated', { filters });

            });

            $scope.$on('homePage:mobile:changeSubView', ($event, { resetStack } = {}) => {
                $scope.mobileSubView = homePageService.getMobileSubView();

                if (resetStack) {
                    delete $scope.selectedView;
                    feedDropStack = [];
                }
            });

            $scope.$on('homePage:mobile:songChanged', ($evt, song) => {
                homePageService.setMobileSubView(homePageService.SUB_VIEWS.SONG);
                $scope.selectedSong = song;

                $scope.songLoading = true;
                if (!window.onSongIframeLoaded) {
                    window.onSongIframeLoaded = () => {
                        $scope.songLoading = false;
                    }
                }
            });

            $timeout(() => {
                $scope.ready = true;
            });

            if ($rootScope.isMobile) {
                homePageService.setMobileSubView(homePageService.SUB_VIEWS.ROOMS);
            }

        }
    };
});
