import angular from 'angular';
import template from './users.html';
import './users.less';

import {
    USERS_SORT_OPTIONS,
    USERS_SORT_OPTIONS_KEYS,
    USERS_SORT_OPTIONS_STORAGE_KEY
} from '../../../../../shared/consts';

if (MyGlobal.project.isMobile) {
    import('./users_mobile.less');
}

import './user/user';

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myHomePageUsersColumn', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, helper, userService, storage) {

            let currQuery;

            const selectedSort = storage.getItem(USERS_SORT_OPTIONS_STORAGE_KEY) || USERS_SORT_OPTIONS_KEYS.FOLLOWERS;
            $scope.sortOptions = USERS_SORT_OPTIONS;

            const updateSelectedSort = (key) => {
                $scope.sortOptions = $scope.sortOptions.map(item => {
                    if (key === item.key) {
                        item.selected = true;
                    } else {
                        delete item.selected;
                    }

                    return item;
                });
            };

            if (selectedSort) {
                updateSelectedSort(selectedSort);
            }

            const load = async ({ append, sort } = {})=> {

                sort = sort || storage.getItem(USERS_SORT_OPTIONS_STORAGE_KEY) || USERS_SORT_OPTIONS_KEYS.FOLLOWERS;

                const { models } = await userService.getUsers({
                    query: currQuery || undefined,
                    sort
                });

                const { title, field } = userService.getUserSortStatsTitleAndField(sort);
                $scope.statsTitle = title;
                $scope.statsField = field;

                if (append) {
                    $scope.users = ($scope.users || []).concat(models);
                } else {
                    $scope.users = models;
                }
            };

            $scope.search = async (query, sort) => {
                try {
                    currQuery = query;

                    await load({
                        sort
                    });

                    storage.setForeverItem(USERS_SORT_OPTIONS_STORAGE_KEY, sort);
                    updateSelectedSort(sort);

                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.clearSearch = async () => {
                try {
                    currQuery = undefined;
                    await load();
                } catch(e) {
                    helper.error(e);
                } finally {
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            (async () => {
                try {
                    $scope.loading = true;
                    await load();
                } catch(e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            })();
        }
    };
});
