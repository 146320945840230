/**
 * Created by Shlomi on 10/09/2014.
 */

(function(angular){

    var app = angular.module(MyGlobal.page.ngAppName);

    app.controller('MainCtrl', function ($scope, $http, helper, storage, $document,
                                         $timeout, $state, pageLoadManager, popupService,
                                         $rootScope, roomService, analyticsService) {

        $scope.hideBanner = $rootScope.hideBanner = helper.getUrlParam('hb');
        $scope.rooms = [];
        $scope.favorites = [];
        $scope.pageType = location.pathname.substr(1);

        $scope.onMainPageCTAClicked = async ($event) => {

            if ($scope.loadingCTA) return;

            $scope.loadingCTA = true;

            try {
                $event.preventDefault();
                $scope.triggerMainPageEvent('clickedOnPlayAndDiscover');

                let room = await roomService.findTheBestRoom();

                if (!room) {
                    room = $scope.limboRooms?.length && $scope.limboRooms[0];
                }

                roomService.gotoRoom(room);
            } catch (e) {
                helper.error(e);
            }

            $scope.loadingCTA = false;
        }

        $scope.triggerMainPageEvent = function(eventType){
            analyticsService.trackEvent({
                eventCategory: 'Banner',
                eventAction: eventType
            });
        };
        // Functions
        $scope.scrollTo = function(elem, top){
            if(elem && !isNaN(top)){
                $document.scrollToElementAnimated(elem, top);
            }
        };

        $scope.$on('scrollTo', function ($evt, where) {
            var elem;
            if(where === 'youtube'){
                elem = $('#youtubeBanner');
                $scope.showYouTubeBanner = true;
                $scope.showSpotifyBanner = false;
            }else if(where === 'spotify'){
                elem = $('#spotifyBanner');
                $scope.showYouTubeBanner = false;
                $scope.showSpotifyBanner = true;
            }else{
                $scope.showYouTubeBanner = false;
                $scope.showSpotifyBanner = false;
                elem = $('#mainBanner');
            }

            $timeout(function () {
                $scope.scrollTo(elem, 0);
            });
        });

        // Alias
        $scope.scrollToRooms = function () {
            $scope.triggerMainPageEvent('ClickedOnBrowseRooms');
            $scope.scrollTo($('#roomList'), 0);
        };

        $scope.scrollToLearnMore = function () {
            $scope.scrollTo($('#learn-more'), 0);
        };

        $scope.scrollMainBanner = function () {
            helper.gotoState('/');
        };

        $scope.scrollToYouTubeBanner = function () {
            helper.gotoState('youtube');
        };

        $scope.scrollToSpotifyBanner = function () {
            helper.gotoState('spotify');
        };

        if(helper.getUrlParam('t') && helper.getUrlParam('h')){
            MyGlobal.page.t = helper.getUrlParam('t');
            MyGlobal.page.h = helper.getUrlParam('h');
        }

        if(!MyGlobal.user && MyGlobal.page.t && MyGlobal.page.h && helper.getUrlParam('sentBy')){
            helper.gotoState('join', {
                message: 'Please sign-up to join '+helper.getUrlParam('sentBy')+'\'s room'
            });
        }

        // Load Limbo rooms

        var limboRoomTexts = roomService.getLimboRoomTexts();
        $scope.limboRoomText = limboRoomTexts[helper.getRandomInt(0, limboRoomTexts.length - 1)];

        roomService.loadLimboRooms().then(function (rooms) {
            $scope.limboRooms = rooms;
        }).catch(function (err) {
            helper.error(err);
        });

        if (helper.getUrlParam('postAccountDeletion')) {
            popupService.openPostAccountDeletionPopup();
        }

        // Page loaded well, remove the shit..
        helper.removePagePreloader();
    });

}(angular));
