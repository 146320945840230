/**
 * Created by Shlomi on 22/04/2015.
 */

(function(angular) {
    var app = angular.module(MyGlobal.page.ngAppName);

    app.config(function ($stateProvider) {

        $stateProvider.state('youtube', {
            url: '/youtube',
            onEnter: function ($rootScope, $timeout) {
                $timeout(function () {
                    $rootScope.$broadcast('scrollTo', 'youtube');
                });
            }
        }).state('spotify', {
            url: '/spotify',
            onEnter: function ($rootScope, $timeout) {
                $timeout(function () {
                    $rootScope.$broadcast('scrollTo', 'spotify');
                });
            }
        }).state('mobileTab', {
            url: '/mobileTab/:tabName',
            onEnter: ($stateParams, homePageService) => {
                const { tabName } = $stateParams;

                if (Object.keys(homePageService.SUB_VIEWS).includes(tabName)) {
                    homePageService.setMobileSubView(tabName);
                }
            }
        });
    });
})(angular);
