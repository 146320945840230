import angular from 'angular';
import './postAccountDeletionPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyPostAccountDeletionPopupCtrl', ($scope, $uibModalInstance, storage, $timeout,
                                                  userService, analyticsService, helper, $rootScope) => {

    const data = storage.getItem('postDeletionData') || {};

    $scope.reason = {};

    $scope.submit = async ($event) => {
        try {
            $event.preventDefault();

            if (!$scope.reason?.val) return;

            $scope.loading = true;

            await userService.sendAccountDeletionFeedback({
                ...data,
                reason: $scope.reason.val
            });

            storage.removeItem('postDeletionData');

            await analyticsService.trackRetentionEventP({
                eventAction: 'UserSharedDeletionReason',
                eventLabel: `${data.score}_${data.beats}`
            });

            $rootScope.showNotification('Thank you for your feedback!');

            $timeout(() => {
                helper.redirect('/');
            }, 2000);

        } catch(e) {
            delete $scope.loading;
            $scope.error = e.message;
        } finally {
            try { $scope.$digest(); } catch(e) {}
        }
    }

    $scope.close = function () {
        $uibModalInstance.dismiss();
    };

    if (!data._id || !data.userName || !data.email) {
        $timeout(()=> {
            $scope.close();
            helper.redirect('/');
        });
    }
});
