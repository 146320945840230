import angular from 'angular';

import '../../shared/roomTile/roomTile';
import './roomsPopup.less';

const app = angular.module(MyGlobal.page.ngAppName);

app.controller('MyRoomsPopupCtrl', ($scope, $uibModalInstance, helper, roomService, $timeout, $stateParams) => {

    const roomsHolderSelector = '#roomsPopup .listHolder';

    let pagination;
    let scrollLocked;
    let querySearch;
    let currPage = 1;

    $scope.filters = $stateParams.filters || {};

    $scope.close = function(){
        $uibModalInstance.dismiss();
    };

    const loadRooms = async ({ append } = {}) => {
        const { models, pagination: newPagination } = await roomService.getRooms({
            query: querySearch,
            filters: $scope.filters,
            page: currPage,
            itemsPerPage: 20
        });

        if (append) {
            $scope.rooms = ($scope.rooms || []).concat(models);
        } else {
            $scope.rooms = models;
        }

        pagination = newPagination;

        $timeout(() => {
            $(roomsHolderSelector).perfectScrollbar('update');
        });
    };

    $scope.toggleFavorites = async ()=> {
        let orig = $scope.filters.favorites;
        try {
            $scope.filters.favorites = !$scope.filters.favorites;
            $scope.loading = true;
            await loadRooms();
        } catch(e) {
            helper.error(e);
            $scope.filters.favorites = orig;
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    };

    $scope.doSearch = async (query)=> {
        currPage = 1;
        querySearch = query;
        return await loadRooms();
    };
    $scope.clearSearch = ()=> {
        currPage = 1;
        querySearch = undefined;
        return loadRooms();
    };

    (async () => {
        try {

            // Load the rooms - first time
            $scope.loading = true;
            await loadRooms({
                append: false
            });

            $timeout(() => {
                const roomsHolder = $(roomsHolderSelector);

                roomsHolder.on('ps-y-reach-end', async () => {
                    try {
                        if (!pagination || pagination.page >= pagination.totalPages) {
                            return;
                        }

                        if(scrollLocked) return;
                        scrollLocked = true;

                        $timeout(() => {
                            scrollLocked = false;
                        }, 500);

                        currPage++;
                        await loadRooms({ append: true });
                    } catch(e) {
                        helper.error(e);
                    }
                });
            })
        } catch(e) {
            helper.error(e);
        } finally {
            $scope.loading = false;
            try { $scope.$digest(); } catch(e) {}
        }
    })();

});
