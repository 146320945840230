import angular from 'angular';

import template from './rooms.html';
import './rooms.less';

import '../../shared/roomTile/roomTile';

if (MyGlobal.project.isMobile) {
    import('./rooms_mobile.less');
}

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myHomePageRoomsColumn', () => {
    return {
        restrict: 'E',
        template,
        scope: {},
        controller($scope, $q, roomService, helper, userService) {

            let currPage = 1;
            $scope.filters = {};

            $scope.loadRooms = async () => {
                const { models } = await roomService.getRooms({
                    query: $scope.query,
                    filters: $scope.filters,
                    page: currPage,
                    itemsPerPage: 20
                });

                $scope.rooms = models;

                if (!userService.isLoggedIn() && $scope.rooms?.length) {
                    const ONLINE_USERS_THRESHOLD = 5;

                    const limbo = $scope.rooms.find(item => item.isLimbo);
                    const rest = $scope.rooms.filter(item => !item.isLimbo).sort((a, b) => {
                        const aIsActivePublic = a.type === 'public' && (a.onlineUsers.all || 0) >= ONLINE_USERS_THRESHOLD;
                        const bIsActivePublic = b.type === 'public' && (b.onlineUsers.all || 0) >= ONLINE_USERS_THRESHOLD;

                        if (aIsActivePublic && !bIsActivePublic) {
                            return -1;
                        } else if (!aIsActivePublic && bIsActivePublic) {
                            return 1;
                        }
                        return 0;
                    });

                    $scope.rooms = limbo ? [limbo, ...rest] : rest;
                }
            };

            $scope.toggleFavorites = async ()=> {
                let orig = $scope.filters.favorites;
                try {
                    $scope.filters.favorites = !$scope.filters.favorites;
                    $scope.loading = true;
                    await $scope.loadRooms();
                } catch(e) {
                    helper.error(e);
                    $scope.filters.favorites = orig;
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            };

            $scope.search = async (query) => {
                $scope.query = query;
                return await $scope.loadRooms();
            };

            $scope.clearSearch = async () => {
                delete $scope.query;
                return $scope.loadRooms();
            };

            (async () => {
                try {
                    // Load the rooms - first time
                    $scope.loading = true;
                    await $scope.loadRooms({
                        append: false
                    });
                } catch(e) {
                    helper.error(e);
                } finally {
                    $scope.loading = false;
                    try { $scope.$digest(); } catch(e) {}
                }
            })();

        }
    };
});
