import angular from 'angular';
import template from './user.html';
import './user.less';

const app = angular.module(MyGlobal.page.ngAppName);

if (MyGlobal.project.isMobile) {
    import('./user_mobile.less');
}

app.directive('myUser', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            user: '=',
            statsTitle: '=',
            statsField: '=',
            onClick: '&'
        },
        controller($scope, helper, userService) {
            $scope.toggleFollow = async ($event)=> {
                $event.stopImmediatePropagation();
                $event.preventDefault();
                try {
                    if (!$scope.user.follows) {
                        $scope.user.follows = true;
                        $scope.user.followers = ($scope.user.followers || 0) + 1;
                        await userService.follow($scope.user._id);
                    } else {
                        $scope.user.follows = false;
                        $scope.user.followers = $scope.user.followers > 0 ? $scope.user.followers - 1 : 0;
                        await userService.unFollow($scope.user._id);
                    }
                } catch(e) {
                    helper.error(e);
                }
            };

            $scope.getMusicTags = (musicTags) => {
                return musicTags.map(item => item.tag.name).join(', ');
            }

            $scope.itsMe = (user) => {
                return userService.isCurrUser(user._id);
            };

        }
    };
});
