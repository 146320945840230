import angular from 'angular';
import './columnHeader.less';
import template from './columnHeader.html';
import '../../../shared/sortOptions/sortOptions'

const app = angular.module(MyGlobal.page.ngAppName);

app.directive('myHomePageColumnHeader', () => {
    return {
        restrict: 'E',
        template,
        scope: {
            title: '=',
            innerText: '=',
            sortOptions: '=',
            onSearch: '&',
            onSearchClear: '&'
        },
        controller($scope) {

            $scope.doSearch = (query, sort) => {
                $scope.searchQuery = query;
                return $scope.onSearch({
                    query: $scope.searchQuery,
                    sort
                });
            };

            $scope.clearSearch = () => {
                delete $scope.searchQuery;
                return $scope.onSearchClear();
            };

            $scope.showSearch = ($event) => {
                $event.stopImmediatePropagation();
                $scope.displaySearch = true;
            };

            $scope.cancelSearch = () => {
                if ($scope.searchQuery) {
                    $scope.clearSearch();
                }

                $scope.displaySearch = false;
                try { $scope.$digest(); } catch(e) {}
            };

            $scope.onSortOptionSelectedInt = (sortOption) => {
                $scope.doSearch($scope.searchQuery, sortOption.key);
            };

            $scope.$on('document:click', () => {
                $scope.cancelSearch();
                $scope.showSortOptions = false;
                try { $scope.$digest(); } catch(e) {}
            });
        }
    };
});
